const Config = {
  urls: {
    aryaCalendar: 'https://calendar-staging.goarya.com',
    aryaConnectNotifications: 'https://connectapi-staging.goarya.com/hubs/connect',
    aryaConnect: 'https://connectapi-staging.goarya.com/api/v1',
    getWhiteLabelResourceUrl: client => `https://arya3-whitelabel.s3.amazonaws.com/${client.toLowerCase()}-review`,
    aryaUrl: 'https://app-staging.goarya.com',
  },
};

export default Config;
